<template>
  <section class="pp-sec-pad">
    <div class="cp-c-pad container" style="margin: 50px auto;">
      <div align="center"></div>
      <div>
        <a href="javascript:void(0);" class="nuxt-link-active" title="Back" @click.prevent="onBack">&lt;&lt;&lt;</a>
      </div>
      <div class="tc-box article-box">
        <h2>
          {{ news.title }}
        </h2>
        <div class="article-infobox">
          <span>{{ news.timestamp }}</span>
        </div>
        <hr>
        <div class="ql-container ql-snow">
          <span v-html="news.content"></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState, useStore } from 'vuex'
  import news from "@/data/news";
  export default {
    name: "NewsDetailView",
    data() {
      return {
        news: {}
      }
    },
    computed: {
      ...mapState({
        language: state => state.app.language
      })
    },
    watch: {
      language: function(newValue) {
        this.news = news[newValue].filter((e) => {
          return e.id == this.id
        })[0]
      },
      '$rou.query': function(newValue) {
        this.id = newValue.id
        if(!this.id) {
          return
        }
        this.news = news[this.language].filter((e) => {
          return e.id == this.id
        })[0]
      }
    },
    created() {
      this.id = this.$route.query.id
      this.store = useStore()
      this.news = news[this.store.state.app.language].filter((e) => {
        return e.id == this.id
      })[0]
    },
    methods: {
      onBack() {
        this.$router.back()
      }
    }
  }
</script>

<style>
.ql-editor img {
  width: 100%;
}
</style>